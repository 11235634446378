const Assets = [
    {
        id:0,
        // text: 'test00',
        primaryImage: './Assets/album__asset00.jpg',
        subImage00: './Assets/album__asset01.png',
        subImage01: './Assets/album__asset02.jpg',
        textLine1:'(10/2021)',
        // textLine2:'Lorem Ipsum',
        // textLine3:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        textLine4:'Gabby Diaz',
    },
    {
        id:1,
        // text: 'test01',
        primaryImage: './Assets/album__asset03.png',
        subImage00: './Assets/album__asset04.jpg',
        subImage01: './Assets/album__asset05.jpg',
        textLine1:'(07/2021)',
        // textLine2:'Lorem Ipsum',
        // textLine3:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        textLine4:'Gabby Diaz',
    },
    {
        id:2,
        // text: 'test03',
        primaryImage: './Assets/album__asset10.JPG',
        subImage00: './Assets/album__asset11.jpg',
        subImage01: './Assets/album__asset12.jpg',
        textLine1:'(03/2021)',
        // textLine2:'Lorem Ipsum',
        // textLine3:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        textLine4:'Gabby Diaz',
    },
    {
        id:3,
        // text: 'test02',
        primaryImage: './Assets/album__asset10.png',
        subImage00: './Assets/album__asset11.png',
        subImage01: './Assets/album__asset06.jpg',
        textLine1:'(04/2021)',
        // textLine2:'Lorem Ipsum',
        // textLine3:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        textLine4:'Gabby Diaz',
    },
    {
        id:4,
        // text: 'test03',
        primaryImage: './Assets/album__asset07.png',
        subImage00: './Assets/album__asset08.jpg',
        subImage01: './Assets/album__asset09.jpg',
        textLine1:'(03/2021)',
        // textLine2:'Lorem Ipsum',
        // textLine3:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        textLine4:'Gabby Diaz',
    },
]

export default Assets