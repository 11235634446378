import React from 'react';
import '../Styling/Featured.css';

function Featured() {
    return (
        <div className='featured'>
            
        </div>
    )
}

export default Featured
